@import "https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap";
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Regular.ed77b881.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter-Italic.17850d8c.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-Medium.54f1243d.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter-MediumItalic.ebe4cf3b.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-Bold.af5441a3.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter-BoldItalic.31bfb722.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter-ExtraBold.c2a0acf6.woff2") format("woff2");
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter-Black.20ec9d84.woff2") format("woff2");
}

:root {
  --mnw-color-success: #5bb98c;
  --mnw-color-warning: #ad5700;
  --mnw-color-warning-background: #fff4d5;
  --mnw-color-error: #cd2b31;
  --mnw-color-error-background: #ffefef;
  --mnw-color-1: #0072ce;
  --mnw-color-2: #006adc;
  --mnw-color-3: #f8f9fa;
  --mnw-color-4: #24272a;
  --mnw-color-5: #dfe3e6;
}

/*# sourceMappingURL=index.d8979ace.css.map */
